.Wrapper {
  padding-top: 70px;
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (min-width: 600px) {
  .Wrapper {
  }
}

@media only screen and (min-width: 768px) {

}

@media only screen and (min-width: 900px) {
  .Wrapper {
  }
}

@media only screen and (min-width: 1024px) {

}

@media only screen and (min-width: 1200px) {

}