@import "../../../assets/styles/style.scss";

#header {
  position: fixed;
  width: 220px;
}
#header .pro-sidebar {
  height: 100vh;
}
#header .close-menu {
  color: #000;
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-size: 22px;
  top: 55px;
  cursor: pointer;
}
#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
}
#header .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}
#header .pro-sidebar-inner {
  background-color: white;
  box-shadow: 0.5px 0.866px 2px 0 rgba(0, 0, 0, 0.15);
}
#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}
#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: #000;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #000;
  margin: 10px 0;
  border-bottom: 1px solid rgba(243, 243, 243, 0.5);;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  background-color: white;
  color: #000;
  border-radius: 3px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
  color: #000;
}
#header .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  background-color: white;
}
.pro-sidebar-inner .pro-sidebar-layout .active .select{
  display: block;
}
#header .pro-sidebar-inner .pro-sidebar-layout .active .pro-item-content{
  font-style: normal;
  font-size: 16px;
  line-height: 20px;

  color: #0E7CE2;
}
#header .pro-sidebar-footer {
  height: 38px;
}
#header .pro-sidebar-footer .pro-menu {
  margin: 0;
  padding: 0;
}
#header .pro-sidebar-footer .pro-menu .pro-inner-item {
  margin: 0 !important;
  padding: 0;
}
#header .pro-sidebar-content .pro-menu-item .popper-inner {
  background-color: white;
}
#header .logo {
  padding: 20px;
}

@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}


.pro-sidebar-content{
  font-family: $font-main;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #0E7CE2;
}

.pro-menu{
  font-size: 12px;
}


.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  position: initial !important;
}

.pro-sidebar .pro-menu .pro-menu-item {
  font-family: $font-main;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4F4F4F;
}

.pro-menu-item .react-slidedown{
  transition-duration: 0s;
}