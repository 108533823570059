@import "./colors";

.text-blue {
  color: $app-blue;
}

.text-bold {
  font-weight: bold;
}

h2 {
  margin-top: 0;
}

.text-uppercase {
  text-transform: uppercase;
}

.cursor-pointer {
  cursor: pointer;
}
