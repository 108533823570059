@import '../../../assets/styles/style';

.SideBar {
  z-index: 1;

  .LogoText{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    background-color: #0E7CE2;

    img.BigLogo {
      max-width: 138px;
      max-height: 36px;
      width: auto;
      height: auto;
    }
    img.SmallLogo {
      width: 26px;
      height: 26px;
    }
  }
  .CloseMenu {
    right: -12px !important;
    top: 28px !important;
    img{
      width: 24px;
      height: 24px;
    }
  }
}
