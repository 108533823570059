@import '../../../assets/styles/style';

.Wrapper {
  width: 100%;
  height: 100%;

  .Content {
    width: 100%;
    font-family: $font-main;
  }
}
