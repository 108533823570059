body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 15px;
  overflow-x: hidden;
}

body {
  height: 100vh;
  display: flex;
  overflow-y: scroll;
  color: #4F4F4F;
  background: #ECF6FF;
}

body {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.28581;
  text-transform: none;
}

p {
  margin-top: 0;
}
